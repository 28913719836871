<template>
  <cv-modal
    :visible="showModal"
    size="xs"
    @primary-click="addToCart"
    @secondary-click="close"
    @modal-hidden="close"
    @modal-shown="resetQty"
  >
    <template slot="title">Aggiungi all'ordine</template>
    <template slot="content">
      <div class="bx--row">
        <div class="bx--col">
          <h4>{{ item.cat }}</h4>
          <h2>{{ item.descr }}</h2>
          <div>{{ item.size }}</div>
          <div class="text-uppercase">{{ item.brand }}</div>
        </div>
      </div>
      <hr />
      <qtyselector
        :cartItemQty="1"
        @incrementQty="incrementQty"
        @decrementQty="decrementQty"
        @setQty="setQty"
        ref="qtyselector"
      />
    </template>
    <template slot="primary-button">Aggiungi</template>
    <template slot="secondary-button">Annulla</template>
  </cv-modal>
</template>

<script>
import ItemQtySelector from '../ItemQtySelector/ItemQtySelector';

export default {
  emits: ['close'],
  name: 'Modal',
  components: { qtyselector: ItemQtySelector },
  props: {
    index: String,
    item: {},
    showModal: Boolean
  },
  data() {
    return {
      qty: 1
    };
  },
  mounted() {},
  methods: {
    incrementQty() {
      this.qty++;
    },
    decrementQty() {
      if (this.qty > 1) this.qty--;
    },
    setQty(qty) {
      this.qty = parseInt(qty);
    },
    close() {
      this.$emit('close');
    },
    addToCart() {
      let item = this.item;
      item.qty = this.qty;
      this.$store.commit('addCartItem', { index: this.index, item: item });
      this.$emit('close');
    },
    resetQty() {
      this.qty = 1;
      this.$refs.qtyselector.resetQty();
    }
  }
};
</script>

<style lang="scss">
@use '../../styles/theme';
@use 'carbon-components/scss/globals/scss/spacing' as s;

.cv-modal {
  .bx--modal-container {
    margin-top: env(safe-area-inset-top);
  }

  h4 {
    margin-top: s.$spacing-05;
  }
  h2 + div {
    margin-bottom: s.$spacing-06;
  }
  hr {
    margin: s.$spacing-06 0;
    color: theme.$background;
  }
}
</style>
