<template>
  <div id="app">
    <Header />
    <cv-content id="#main-content">
      <router-view />
    </cv-content>
    <footer>powered by <a href="https://www.tapecode.it" style="color: #000">Tapecode</a></footer>
</div>
</template>

<script>
import Header from './components/Header';

export default {
  name: 'App',
  components: {
    Header
  }
};
</script>

<style lang="scss">

@import './styles/theme';

@import 'carbon-components/scss/globals/scss/styles';

@import './styles/type';

html {height:100%; max-width: 1000px; margin: 0 auto;}
body {height: 99%;}

#app {
  display: flex;
  flex-direction: column;
  height: 100%;

  main {
    flex: 1 0 auto;
  }

  footer {
    text-align: center;
    font-size: 0.8rem;
    flex-grow: 0;
    flex-shrink: 0;
    padding: 0.5rem;
  }
}

.bx--header ~ .bx--content {
  margin: calc(5rem + env(safe-area-inset-top)) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  padding-top: 0;
  position: relative;
}
</style>