import Vue from 'vue';
import Router from 'vue-router';
import ProductsPage from './views/ProductsPage';

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '*',
      name: 'products-page',
      component: () =>
        import(/* webpackChunkName: "products-page" */ './views/ProductsPage') 
    },
    {
      path: '/checkout',
      name: 'checkout-page',
      // route level code-splitting
      // this generates a separate chunk (repo-page.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "checkout-page" */ './views/CheckoutPage')
    },
    {
      path: '/confirm-order',
      name: 'confirm-order',
      // route level code-splitting
      // this generates a separate chunk (repo-page.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "confirm-order" */ './views/ConfirmOrder')
    }
  ],
  mode: 'history'
});
