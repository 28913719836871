<template>
  <div>
    <div
      v-if="rows.length == 0"
      class="bx--type-expressive-paragraph-01"
      style="padding:1rem"
    >
      Non ci sono risultati per la ricerca che hai effettuato!
    </div>
    <cv-structured-list selectable v-else>
      <template slot="headings"> </template>
      <template slot="items">
        <cv-structured-list-item
          @click="selectToCart(row, rowIndex)"
          v-for="(row, rowIndex) in rows"
          :key="`${rowIndex}`"
        >
          <cv-structured-list-data>
            <h4 class="category">{{ row.cat }}</h4>
            <h2>{{ row.descr }}</h2>
            <cv-row>
              <cv-column class="text-uppercase">
                {{ row.brand }}
              </cv-column>
              <cv-column style="text-align: right;">
                {{ row.size }}
              </cv-column>
            </cv-row>
          </cv-structured-list-data>
          <cv-structured-list-data>
            <AddFilled32 class="primary-fill" />
          </cv-structured-list-data>
        </cv-structured-list-item>
        <addtocart
          @close="close"
          :showModal="showModal"
          :index="idx"
          :item="item"
        />
      </template>
    </cv-structured-list>
  </div>
</template>

<script>
import Modal from './Modal.vue';
import ItemQtySelector from '../ItemQtySelector/ItemQtySelector';

export default {
  name: 'ProductsList',
  components: { addtocart: Modal },
  props: {
    cat: Number,
    rows: Array
  },
  data() {
    return {
      showModal: false,
      idx: '',
      item: {}
    };
  },
  methods: {
    selectToCart(item, index) {
      this.showModal = true;
      this.idx = `${this.cat}-${index}`;
      this.item = item;
    },
    close() {
      this.showModal = false;
    }
  },
  computed: {}
};
</script>

<style lang="scss">
@use '../../styles/theme';

.bx--structured-list-thead,
.bx--structured-list--selection
  .bx--structured-list-tbody
  .bx--structured-list-td:last-child {
  display: none;
}
.bx--structured-list-tbody .bx--structured-list-td {
  min-width: 4rem;
}
h4 {
  color: theme.$brand-01;
}
h2,
h4 {
  text-transform: capitalize;
}
svg.primary-fill {
  fill: theme.$interactive-01;
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
  left: 50%;
}
</style>
