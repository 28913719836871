import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    cartQty: 0,
    cart: {}
  },
  mutations: {
    addCartItem(state, data) {
      if (data.index in state.cart) {
        Vue.set(state, 'cartQty', state.cartQty + data.item.qty);
        Vue.set(
          state.cart[data.index],
          'qty',
          state.cart[data.index].qty + data.item.qty
        );
      } else {
        let qty = state.cartQty + data.item.qty;
        Vue.set(state.cart, data.index, data.item);
        Vue.set(state, 'cartQty', qty);
      }
    },
    removeCartItem(state, idx) {
      Vue.set(state, 'cartQty', state.cartQty - state.cart[idx].qty);
      Vue.delete(state.cart, idx);
    },
    incrementQty(state, idx) {
      Vue.set(state, 'cartQty', state.cartQty + 1);
      Vue.set(state.cart[idx], 'qty', state.cart[idx].qty + 1);
    },
    decrementQty(state, idx) {
      Vue.set(state, 'cartQty', state.cartQty - 1);
      Vue.set(state.cart[idx], 'qty', state.cart[idx].qty - 1);
    },
    setQty(state, data) {
      let qty = state.cartQty - state.cart[data.idx].qty;
      Vue.set(state, 'cartQty', qty + data.qty);
      Vue.set(state.cart[data.idx], 'qty', data.qty);
    }
  },
  actions: {},
  plugins: [vuexLocal.plugin],
});
