import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { sync } from 'vuex-router-sync';
import axios from 'axios';
import VueAxios from 'vue-axios';
import CarbonComponentsVue from '@carbon/vue';
import { CvDatePicker } from '@carbon/vue';
import { CvIconButton } from '@carbon/vue/src/components/cv-button';
import { CarbonIconsVue } from '@carbon/icons-vue';
import AddFilled32 from '@carbon/icons-vue/es/add--filled/32';
import AddAlt32 from '@carbon/icons-vue/es/add--alt/32';
import SubtractAlt32 from '@carbon/icons-vue/es/subtract--alt/32';
import SendAltFilled32 from '@carbon/icons-vue/es/send--alt--filled/32';
import VueGtag from "vue-gtag";

Vue.use(CarbonComponentsVue);
Vue.use(CvIconButton);
Vue.use(CvDatePicker);
Vue.use(CarbonIconsVue, {
  components: {
    AddFilled32,
    AddAlt32,
    SubtractAlt32,
    SendAltFilled32
  }
});

Vue.use(VueAxios, axios);
Vue.prototype.$axios = axios;

sync(store, router);

const waitForStorageToBeReady = async (to, from, next) => {
  await store.restored;
  next();
};

router.beforeEach(waitForStorageToBeReady);

Vue.use(VueGtag, {
  config: { id: "G-RG52C0Y0S7" }
}, router);

const app = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
