<template>
  <cv-row>
    <cv-column>
      <cv-button
        kind="ghost"
        tip-text="diminuisci"
        @click="decrementQty"
        :icon="SubtractAlt32"
      ></cv-button>
    </cv-column>
    <cv-column>
      <input
        type="number"
        v-model="qty"
        @change="setQty"
        :class="`bx--text-input`"
        placeholder=""
      />
    </cv-column>
    <cv-column>
      <cv-button
        kind="ghost"
        :icon="AddAlt32"
        tip-text="aumenta"
        @click="incrementQty"
      ></cv-button>
    </cv-column>
  </cv-row>
</template>

<script>
import AddAlt32 from '@carbon/icons-vue/es/add--alt/32';
import SubtractAlt32 from '@carbon/icons-vue/es/subtract--alt/32';

export default {
  name: 'ItemQtySelector',
  emits: ['incrementQty', 'decrementQty', 'setQty'],
  data() {
    return {
      AddAlt32,
      SubtractAlt32,
      qty: 1
    };
  },
  props: {
    cartItemQty: Number,
    currItem: String
  },
  mounted() {
    this.qty = this.cartItemQty;
  },
  methods: {
    incrementQty() {
      this.qty++;
      this.$emit('incrementQty');
    },
    decrementQty() {
      if (this.qty > 1) {
        this.qty--;
        this.$emit('decrementQty');
      }
    },
    setQty() {
        (this.currItem == null ? this.$emit('setQty', this.qty) : this.$emit('setQty', {idx: this.currItem, qty: parseInt(this.qty)}));
    },
    resetQty(){
        this.qty = 1;
    }
  }
};
</script>

<style lang="scss">
.items-cart-actions {
  text-align: center;
}
input {height: 3rem;}
.cv-button svg.bx--btn__icon {width:2rem;height:2rem;}
</style>
